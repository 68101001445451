import { Link } from "react-router-dom";
import React from "react";

export default function Footer() {
  return (
    <div className="footer__area pb-40">
      <div className="container">
        <div className="footer-items-container">
          <div className="footer__area-widget-about-logo">
            <Link to="/">
              <img src="assets/img/logo.png" alt="" />
            </Link>
          </div>
          <div className="footer__area-widget-contact-item">
            <div className="footer__area-widget-contact-item-icon">
              <i className="fal fa-map-marked-alt"></i>
            </div>
            <div className="footer__area-widget-contact-item-content">
              <span>
                <a href="https://goo.gl/maps/8N9ENx3N5tj12QnW7">DJS Studios, Skopje 1000</a>
              </span>
            </div>
          </div>
          <div className="footer__area-widget-contact-item">
            <div className="footer__area-widget-contact-item-icon">
              <i className="fal fa-envelope-open-text"></i>
            </div>
            <div className="footer__area-widget-contact-item-content">
              <span>
                <a href="mailto:spasovski.gorgi@yahoo.com">spasovski.gorgi[at]yahoo.com</a>
              </span>
            </div>
          </div>
          <div className="footer__area-widget-contact-item">
            <div className="footer__area-widget-contact-item-icon">
              <i className="fal fa-phone-alt"></i>
            </div>
            <div className="footer__area-widget-contact-item-content">
              <span>
                <a href="tel:+389(76)340402">+389 (76) 340 402</a>
              </span>
            </div>
          </div>
          <div className="footer__area-widget-contact-item">
            <div className="footer__area-widget-contact-item-icon">
              <i className="fab fa-facebook-f"></i>
            </div>
            <div className="footer__area-widget-contact-item-content">
              <span>
                <a
                  href="https://www.facebook.com/people/DJS-Studios/61550036267996/"
                  target="_blank"
                  rel="noreferrer"
                >
                  DJS Studios
                </a>
              </span>
            </div>
          </div>
          <div className="footer__area-widget-contact-item">
            <div className="footer__area-widget-contact-item-icon"></div>
            <div className="footer__area-widget-contact-item-content"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
