import { Link } from "react-router-dom";
import React from "react";

export default function RoomDetailsHeader() {
  return (
    <div
      className="page__banner"
      data-background="assets/img/banner/page-banner-6.jpg"
      style={{
        backgroundImage: `url(https://djsstudios.blob.core.windows.net/images/page-banner-6[1].jpg)`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="page__banner-title">
              <h1>Room Details</h1>
              <div className="page__banner-title-menu">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>-</span>Room Details
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
