import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { Room } from "../../Types/room";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

export default function StudioA({ data }: { data: Room }) {
  return (
    <div className="room__details section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 lg-mb-30">
            <div className="all__sidebar">
              <div className="all__sidebar-item">
                <h5>Summary</h5>
                <div className="all__sidebar-item-price">
                  <ul>
                    <li>
                      <i className="fal fa-bed-alt"></i>
                      {data.bedDescription}
                    </li>
                    <li>
                      <i className="fal fa-users"></i>
                      {data.guestDescription}
                    </li>
                  </ul>
                  <hr />
                  <Link to={data.bookingLink}>
                    Book Now<i className="fal fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="room__details-right">
              <div className="room__details-right-content">
                <h3 className="mb-25">{data.name}</h3>
                <p className="mb-25">{data.description}</p>
                <div className="row mt-35 mb-35">
                  <ImageGallery
                    items={data.images.map((i) => ({
                      original: i,
                      thumbnail: i,
                    }))}
                  />
                </div>
              </div>
              <div className="room__details-right-list">
                <h3>Amenities</h3>
                <div className="row mt-35">
                  {data.amenities.map((amenity) => (
                    <div key={amenity} className="col-md-4 col-sm-6 mb-35">
                      <div className="room__details-right-list-item">
                        <div className="room__details-right-list-item-icon">
                          <img src="assets/img/icon/list-2.png" alt="" />
                        </div>
                        <div className="room__details-right-list-item-title">
                          <h6>{amenity}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="room__details-right-faq mt-50">
                <div className="room__details-right-faq-item">
                  <div className="room__details-right-faq-item-card">
                    <div className="room__details-right-faq-item-card-header">
                      <h5>Do you pay before or after booking?</h5>
                      <i className="far fa-long-arrow-up"></i>
                    </div>
                    <div className="room__details-right-faq-item-card-header-content active">
                      <p>
                        For stays of less than 7 nights, payment is typically expected upon your
                        arrival. You can settle the total amount due at check-in. However, for
                        extended stays of 7 nights or more, we offer the option to arrange partial
                        payments to make your stay more manageable. Please feel free to discuss this
                        with us, and we will be happy to work out a suitable payment schedule that
                        suits your needs. If you have any questions or require further
                        clarification, please do not hesitate to reach out to us. Your comfort and
                        satisfaction are our top priorities, and we are here to assist you in any
                        way we can. We look forward to hosting you and ensuring you have a wonderful
                        stay in our apartment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="room__details-right-faq-item">
                  <div className="room__details-right-faq-item-card">
                    <div className="room__details-right-faq-item-card-header">
                      <h5>What documents are needed for booking?</h5>
                      <i className="far fa-long-arrow-down"></i>
                    </div>
                    <div className="room__details-right-faq-item-card-header-content display-none">
                      <p>
                        In order to make a booking with us, we kindly request that all guests
                        provide a copy of their passport. This is a standard procedure to confirm
                        your identity and to comply with local regulations. Rest assured, your
                        personal information will be handled with the utmost confidentiality and
                        only used for the purpose of your reservation. Please attach a scanned copy
                        or clear photo of your passport to your booking request, and we will process
                        your reservation promptly upon receipt. If you have any concerns or
                        questions regarding this requirement, please do not hesitate to contact us.
                        We are here to assist you and ensure your booking process is hassle-free.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

StudioA.propTypes = {
  data: PropTypes.any,
};
