import { Link } from "react-router-dom";
import React from "react";
import { Room } from "../Types/room";

export default function RoomTileItem({ data }: { data: Room }) {
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 md-mb-30">
      <div className="deluxe__three-item">
        <div className="deluxe__three-item-image">
          <img src={data.imageForTile} alt="" />
          <div className="deluxe__three-item-image-content">
            <h4>
              <Link to={"/room/" + data.id}>Small Suite</Link>
            </h4>
            <p>Praesent non ullamcorper ligula. Proin a mi vitae massa lacinia</p>
            <div className="deluxe__three-item-image-content-meta">
              <ul>
                <li>
                  <i className="fal fa-bed-alt"></i>
                  {data.bedDescription}
                </li>
                <li>
                  <i className="fal fa-users"></i>
                  {data.guestDescription}
                </li>
              </ul>
            </div>
            <div className="deluxe__three-item-image-content-bottom">
              <Link to={"/room/" + data.id}>
                <span className="simple-btn">
                  <i className="far fa-chevron-right"></i>Read More
                </span>
              </Link>
              <p>
                <i className="fas fa-star"></i>
                <span>10</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
