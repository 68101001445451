import { Link } from "react-router-dom";
import React from "react";
export default function StudioX() {
  return (
    <div className="room__details section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 lg-mb-30">
            <div className="all__sidebar">
              <div className="all__sidebar-item">
                <h5>Your Price</h5>
                <div className="all__sidebar-item-price">
                  <ul>
                    <li>
                      <i className="fal fa-bed-alt"></i>(3) bed`&apos;`s
                    </li>
                    <li>
                      <i className="fal fa-users"></i>(6) Guest`&apos;`s
                    </li>
                  </ul>
                  <h4>
                    $219<span>/Night</span>
                  </h4>
                  <Link to="/contact">
                    Book Now<i className="fal fa-long-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="room__details-right">
              <div className="room__details-right-content">
                <h3 className="mb-25">Luxury Room is the best online room for luxury hotels</h3>
                <p className="mb-25">
                  Praesent non ullamcorper ligula. Proin a mi vitae massa lacinia sollicitudin eget
                  eu ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                  consectetur rhoncus lobortis. Curabitur sit amet velit sagittis, pellentesque diam
                  euismod, faucibus quam. Cras non rhoncus ipsum. Quisque mattis arcu metus, a
                  fermentum justo semper in. Aliquam egestas metus at nunc aliquam
                </p>
                <p className="m-0">
                  id molestie ex ornare. Aliquam id arcu vel sem pretium porttitor non maximus diam.
                  Quisque urna turpis, euismod sed elementum vel, pellentesque eu eros. Orci varius
                  natoque penatibus et magnis dis parturient montes, nascetur ridiculus musc.
                </p>
                <div className="row mt-35 mb-35">
                  <div className="col-sm-6 sm-mb-30">
                    <img
                      className="img__full"
                      src="https://djsstudios.blob.core.windows.net/images/hotel-24.jpg"
                      alt=""
                    />
                  </div>
                  <div className="col-sm-6">
                    <img
                      className="img__full"
                      src="https://djsstudios.blob.core.windows.net/images/hotel-25.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <h3 className="mb-25">Special check-in instructions</h3>
                <p className="mb-25">
                  Praesent non ullamcorper ligula. Proin a mi vitae massa lacinia sollicitudin eget
                  eu ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                  consectetur rhoncus lobortis. Curabitur sit amet velit sagittis, pellentesque diam
                  euismod, faucibus quam. Cras non rhoncus ipsum. Quisque mattis arcu metus, a
                  fermentum justo semper in. Aliquam egestas metus at nunc aliquam
                </p>
                <p className="m-0">
                  id molestie ex ornare. Aliquam id arcu vel sem pretium porttitor non maximus diam.
                  Quisque urna turpis, euismod sed elementum vel, pellentesque eu eros. Orci varius
                  natoque penatibus et magnis dis parturient montes, nascetur ridiculus musc.
                </p>
              </div>
              <div className="room__details-right-list">
                <h3>Amenities</h3>
                <div className="row mt-35">
                  <div className="col-md-4 col-sm-6 mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-1.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Room Cleanig</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 sm-mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-2.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Car Parking</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 sm-mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-3.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Swimming pool</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-4.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Pick Up & Drop</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 md-mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-5.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Spa Center</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 sm-mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-6.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Smart Key</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 md-mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-7.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Home Wifi</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 sm-mb-35">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-8.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Beakfist</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="room__details-right-list-item">
                      <div className="room__details-right-list-item-icon">
                        <img src="assets/img/icon/list-9.png" alt="" />
                      </div>
                      <div className="room__details-right-list-item-title">
                        <h6>Fitness Gym</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="room__details-right-faq mt-50">
                <div className="room__details-right-faq-item">
                  <div className="room__details-right-faq-item-card">
                    <div className="room__details-right-faq-item-card-header">
                      <h5>Do you pay before or after booking a hotel?</h5>
                      <i className="far fa-long-arrow-up"></i>
                    </div>
                    <div className="room__details-right-faq-item-card-header-content active">
                      <p>
                        Praesent non ullamcorper ligula. Proin a mi vitae massa lacinia sollicitudin
                        eget eu ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque consectetur rhoncus lobortis. Curabitur sit amet velit
                        sagittis, pellentesque diam euismod, faucibus quam. Cras non rhoncus ipsum.
                        Quisque mattis arcu metus, a fermentum justo semper in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="room__details-right-faq-item">
                  <div className="room__details-right-faq-item-card">
                    <div className="room__details-right-faq-item-card-header">
                      <h5>What documents are needed for hotel booking?</h5>
                      <i className="far fa-long-arrow-down"></i>
                    </div>
                    <div className="room__details-right-faq-item-card-header-content display-none">
                      <p>
                        Praesent non ullamcorper ligula. Proin a mi vitae massa lacinia sollicitudin
                        eget eu ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque consectetur rhoncus lobortis. Curabitur sit amet velit
                        sagittis, pellentesque diam euismod, faucibus quam. Cras non rhoncus ipsum.
                        Quisque mattis arcu metus, a fermentum justo semper in.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="room__details-right-faq-item">
                  <div className="room__details-right-faq-item-card">
                    <div className="room__details-right-faq-item-card-header">
                      <h5>Do hotels charge your card before you check in?</h5>
                      <i className="far fa-long-arrow-down"></i>
                    </div>
                    <div className="room__details-right-faq-item-card-header-content display-none">
                      <p>
                        Praesent non ullamcorper ligula. Proin a mi vitae massa lacinia sollicitudin
                        eget eu ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque consectetur rhoncus lobortis. Curabitur sit amet velit
                        sagittis, pellentesque diam euismod, faucibus quam. Cras non rhoncus ipsum.
                        Quisque mattis arcu metus, a fermentum justo semper in.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
