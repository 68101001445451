import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import StudioA from "../Components/Rooms/StudioA";
import StudioX from "../Components/Rooms/StudioX";
import data from "../roomsData.json";
import { Room } from "../Types/room";
import useScript from "../Hooks/useScript";
import RoomDetailsHeader from "../Components/RoomDetailsHeader";

export default function RoomDetailsPage() {
  const { id } = useParams();
  const [room, setRoom] = useState<unknown>(null);
  useScript("../assets/js/custom.js");
  useEffect(() => {
    if (id === undefined || !data) return setRoom(StudioX());
    const roomData = data.rooms.find((room) => room.id === id) as Room;
    if (roomData) setRoom(<StudioA data={roomData} />);
    else setRoom(StudioX());
  }, [id]);

  return (
    <>
      <RoomDetailsHeader />
      {room}
    </>
  );
}
