import { Link } from "react-router-dom";
import React from "react";
import useScript from "../Hooks/useScript";

export default function AboutPage() {
  useScript("assets/js/custom.js");
  return (
    <>
      <div
        className="page__banner"
        data-background="assets/img/banner/page-banner-1.jpg"
        style={{ backgroundImage: `url("assets/img/banner/page-banner-1.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page__banner-title">
                <h1>About Us</h1>
                <div className="page__banner-title-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>About Us
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offers__area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="offers__area-title">
                <span className="subtitle__one">Company Offers</span>
                <h2>Book now and save up to 35% On hotel rooms</h2>
                <p>
                  hasellus nisi sapien, rutrum placerat sapien eu, rhoncus tempus felis. Nulla non
                  pulvinar enim, vel viverra nunc. Integer condimentum vulputate justo. Morbi
                  rhoncus elit in tellus viverra, vel fermentum orci dictum. Vestibulum non nisi
                  commodo, tincidunt elit non, consectetur tellus. Fusce in commodo velit. In
                  dapibus dui vitae tortor ullamcorper mollis.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="offers__area-image">
                <img className="img__full" src="assets/img/features/offers.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services__two section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6 xl-mb-30">
              <div className="services__two-item">
                <span>01</span>
                <div className="services__two-item-icon">
                  <img src="assets/img/icon/cleaning-1.png" alt="" />
                </div>
                <div className="services__two-item-content">
                  <h4>Room Cleaning</h4>
                  <p>
                    Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 lg-mb-30">
              <div className="services__two-item">
                <span>02</span>
                <div className="services__two-item-icon">
                  <img src="assets/img/icon/car-parking.png" alt="" />
                </div>
                <div className="services__two-item-content">
                  <h4>Car Parking</h4>
                  <p>
                    Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 lg-mb-30">
              <div className="services__two-item">
                <span>03</span>
                <div className="services__two-item-icon">
                  <img src="assets/img/icon/swimming-pool.png" alt="" />
                </div>
                <div className="services__two-item-content">
                  <h4>Swimming pool</h4>
                  <p>
                    Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="services__two-item">
                <span>04</span>
                <div className="services__two-item-icon">
                  <img src="assets/img/icon/location-1.png" alt="" />
                </div>
                <div className="services__two-item-content">
                  <h4>PickUp & Drop</h4>
                  <p>
                    Proin massa augue, lacinia at blandit ac, Fringilla scelerisque tortor. Mauris
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
