import "./App.css";
import "./style/style.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import MainPage from "./Pages/MainPage";
import AboutPage from "./Pages/AboutPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactPage from "./Pages/ContactPage";
import RoomModernPage from "./Pages/RoomModern";
import RoomDetailsPage from "./Pages/RoomDetails";
import RoomListPage from "./Pages/RoomListPage";
import React from "react";

function App() {
  return (
    <>
      <Router>
        <div>
          <Header />
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/room-modern" element={<RoomModernPage />} />
            <Route path="/room-list" element={<RoomListPage />} />
            <Route path="/room/:id?" element={<RoomDetailsPage />} />
            <Route path="/" element={<MainPage />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
