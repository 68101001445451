import { Link } from "react-router-dom";
import React from "react";

export default function Header() {
  return (
    <div className="header__sticky">
      <div className="header__area">
        <div className="container custom__container">
          <div className="header__area-menubar">
            <div className="header__area-menubar-left">
              <div className="header__area-menubar-left-logo">
                <Link to="/">
                  <img src="assets/img/logo.png" alt="" />
                </Link>
                <div className="responsive-menu"></div>
              </div>
            </div>
            <div className="header__area-menubar-right">
              <div className="header__area-menubar-right-menu menu-responsive">
                <ul id="mobilemenu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#">Room</a>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/room-modern">Tiles</Link>
                      </li>
                      <li>
                        <Link to="/room-list">List</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  {/* <li>
                    <Link to="/about">About</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
