import { Link } from "react-router-dom";
import React from "react";
import { Room } from "../Types/room";

export default function RoomListItem({ data }: { data: Room }) {
  return (
    <div className="room__list-item">
      <div className="room__list-item-left">
        <Link to={"/room/" + data.id}>
          <div className="room__list-item-image">
            <img src={data.imageForList} alt="" />
          </div>
        </Link>
      </div>
      <div className="room__list-item-right">
        <div className="room__list-item-right-content">
          <h4>
            <Link to={"/room/" + data.id}>{data.name}</Link>
          </h4>
          <p>{data.subtitle}</p>
          <ul>
            <li>
              <i className="fal fa-bed-alt"></i>
              {data.bedDescription}
            </li>
            <li>
              <i className="fal fa-users"></i>
              {data.guestDescription}
            </li>
          </ul>
        </div>
        <div className="room__list-item-right-meta">
          <div className="room__list-item-right-meta-top">
            <p>
              <i className="fas fa-star"></i>
              <span>10</span>
            </p>
          </div>
          <Link to={"/room/" + data.id}>
            <span className="simple-btn">
              <i className="far fa-chevron-right"></i>Read More
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
