import { Link } from "react-router-dom";
import React from "react";
import useScript from "../Hooks/useScript";

export default function ContactPage() {
  useScript("assets/js/custom.js");
  return (
    <>
      <div
        className="page__banner"
        data-background="assets/img/banner/page-banner-9.jpg"
        style={{ backgroundImage: `url("assets/img/banner/page-banner-9.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page__banner-title">
                <h1>Contact Us</h1>
                <div className="page__banner-title-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Contact Us
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact__area section-padding pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 mb-30">
              <div className="contact__area-title">
                <h3 className="mb-25">Get In Touch</h3>
                <p>
                  We value your inquiries and are here to assist you. Please feel free to contact us
                  anytime, and we&apos;ll be glad to help.
                </p>
              </div>
              <div className="contact__area-info">
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fal fa-phone-alt"></i>
                  </div>
                  <div className="contact__area-info-item-content">
                    <span>Emergency Help</span>
                    <h6>
                      <a href="tel:+389(76)340402">+389 (76) 340 402</a>
                    </h6>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fal fa-envelope"></i>
                  </div>
                  <div className="contact__area-info-item-content">
                    <span>Quick Email</span>
                    <h6>
                      <a href="mailto:spasovski.gorgi@yahoo.com">spasovski.gorgi[at]yahoo.com</a>
                    </h6>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <div className="contact__area-info-item-content">
                    <span>Address</span>
                    <h6>
                      <a href="https://goo.gl/maps/8N9ENx3N5tj12QnW7">DJS Studios, Skopje 1000</a>
                    </h6>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fab fa-facebook-f"></i>
                  </div>
                  <div className="contact__area-info-item-content">
                    <span>Facebook page</span>
                    <h6>
                      <a
                        href="https://www.facebook.com/people/DJS-Studios/61550036267996/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        DJS Studios
                      </a>
                    </h6>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fas fa-plane"></i>
                  </div>
                  <div className="contact__area-info-item-content">
                    <span>Skopje Airport</span>
                    <h6>
                      <a href="https://skp.airports.com.mk/" target="_blank" rel="noreferrer">
                        TAV
                      </a>
                    </h6>
                  </div>
                </div>
                <div className="contact__area-info-item">
                  <div className="contact__area-info-item-icon">
                    <i className="fas fa-taxi"></i>
                  </div>
                  <div className="contact__area-info-item-content">
                    <span>Airport transport</span>
                    <h6>
                      <a
                        href="https://www.wtransporter.com/#timetable"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Wtransporter
                      </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7">
              <div className="contact__area-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2966.093840175615!2d21.44289007727747!3d41.976794959227206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135415765d2cbc71%3A0xf1b0bd67a82fbfa8!2sDJS%20Studios!5e0!3m2!1smk!2smk!4v1692947540854!5m2!1smk!2smk"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
