import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import data from "../roomsData.json";
import { Room } from "../Types/room";
import RoomListItem from "../Components/RoomListItem";
import useScript from "../Hooks/useScript";

export default function RoomListPage() {
  useScript("assets/js/custom.js");
  const [rooms, setRooms] = useState<Room[]>([]);
  useEffect(() => {
    setRooms(data.rooms as Room[]);
  }, []);

  return (
    <>
      <div className="page__banner" data-background="assets/img/banner/page-banner-5.jpg">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page__banner-title">
                <h1>Room List</h1>
                <div className="page__banner-title-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Room List
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="room__list section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 order-first order-xl-1 xl-mb-30">
              {rooms.map((room) => {
                return <RoomListItem key={room.id} data={room} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
