import { Link } from "react-router-dom";
import useScript from "../Hooks/useScript";
import React, { useEffect, useState } from "react";
import data from "../roomsData.json";
import { Review } from "../Types/review";
import { Room } from "../Types/room";

export default function MainPage() {
  useScript("assets/js/custom.js");
  const [reviews, setReviews] = useState<Review[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  useEffect(() => {
    setReviews(data.reviews as Review[]);
    setRooms(data.rooms as Room[]);
  }, []);
  return (
    <>
      <div
        className="banner__area"
        data-background="assets/img/banner-1.jpg"
        style={{ backgroundImage: `url("assets/img/banner-1.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="banner__area-title">
                <h1>
                  The Best Studios<span>in Skopje</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="accommodations__area section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg-6 lg-mb-30">
              <div className="accommodations__area-title">
                <span className="subtitle__one">Accommodations</span>
                <h2>Welcome Our Studios And Apartments</h2>
                <p>
                  Savvy travelers are looking for more than just the next destination on the map.
                  They are looking for a memorable experience and to make new friends along the way.
                </p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6">
              <div className="accommodations__area-right">
                <div className="accommodations__area-right-image">
                  <img src="https://djsstudios.blob.core.windows.net/studio-a/300x250.jpg" alt="" />
                  <div className="accommodations__area-right-image-two">
                    <img
                      src="https://djsstudios.blob.core.windows.net/studio-b/300x250.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="deluxe__area section-padding">
        <div className="container">
          <div className="row align-items-end mb-60">
            <div className="col-xl-5">
              <div className="deluxe__area-title">
                <span className="subtitle__one">Deluxe and Luxury</span>
                <h2>Our Luxury Stdios</h2>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="deluxe__area-btn">
                <ul>
                  <li className="active" data-filter="*">
                    All
                  </li>
                  <li data-filter=".studio">Studio</li>
                  <li data-filter=".apartment">Apartment</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row deluxe__area-active">
            {rooms.map((room, index) => {
              return (
                <div key={index} className={"col-xl-3 col-lg-4 mb-30 " + room.type}>
                  <div className="deluxe__area-item">
                    <div className="deluxe__area-item-image">
                      <img className="img__full" src={room.imageForTile} alt="" />
                    </div>
                    <div className="deluxe__area-item-content">
                      <Link to={"/room/" + room.id} className="text-white h4">
                        {room.name}
                      </Link>
                      <Link className="simple-btn" to={"/room/" + room.id}>
                        <i className="far fa-chevron-right"></i>Book Now
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="video__area"
        data-background="assets/img/video.jpg"
        style={{ backgroundImage: `url("assets/img/video.jpg")` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-7 col-lg-8">
              <div className="video__area-title">
                <h2>Book our studios, and explore the beauty of Skopje.</h2>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-5 col-lg-4">
              <div className="video__area-right"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="services__area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 lg-mb-30">
              <div className="services__area-item">
                <div className="services__area-item-icon">
                  <img src="assets/img/icon/cleaning.png" alt="" />
                </div>
                <div className="services__area-item-content">
                  <h5>
                    <a href="#">Room Cleaning</a>
                  </h5>
                  <p>Rooms are always perfectly clean.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 sm-mb-30">
              <div className="services__area-item">
                <div className="services__area-item-icon">
                  <img src="assets/img/icon/wifi.png" alt="" />
                </div>
                <div className="services__area-item-content">
                  <h5>
                    <a href="#">Room Wifi</a>
                  </h5>
                  <p>We provide high speed internet at our studios.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonial__area section-padding pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="deluxe__area-title">
                <h2>What our customers says about us (Reviews on booking.com): </h2>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="testimonial__area-bg">
                <div className="swiper testimonial__slider">
                  <div className="swiper-wrapper">
                    {reviews.map((review, index) => (
                      <div key={index} className="swiper-slide">
                        <div className="testimonial__area-item">
                          <div className="testimonial__area-item-content">
                            <h4>{review.name}</h4>
                            <span>
                              {review.country} - Rating: {review.rating}
                            </span>
                            <p>{review.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="testimonial__area-item-dots">
                  <div className="pagination"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-padding"></div>
    </>
  );
}
