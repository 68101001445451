import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import data from "../roomsData.json";
import { Room } from "../Types/room";
import RoomTileItem from "../Components/RoomTileItem";
import useScript from "../Hooks/useScript";

export default function RoomModernPage() {
  useScript("assets/js/custom.js");
  const [rooms, setRooms] = useState<Room[]>([]);
  useEffect(() => {
    setRooms(data.rooms as Room[]);
  }, []);
  return (
    <>
      <div className="page__banner" data-background="assets/img/banner/page-banner-4.jpg">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page__banner-title">
                <h1>Room Modern</h1>
                <div className="page__banner-title-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <span>-</span>Room Modern
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modern__room section-padding">
        <div className="container">
          <div className="row">
            {rooms.map((room) => {
              return <RoomTileItem key={room.id} data={room} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
